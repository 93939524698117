import React, { useEffect, useState } from 'react';
import {
  Edit,
  TextInput,
  SelectInput,
  BooleanInput,
  NumberInput,
  SelectArrayInput,
  useGetList,
  AutocompleteArrayInput,
  TabbedForm,
  ArrayInput,
  SimpleFormIterator,
  DateTimeInput,
  AutocompleteInput,
  FormDataConsumer, FunctionField,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { closedGeos } from '../../data/closedGeos';
import { Grid } from '@mui/material';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';


const AppEdit = (props: any) => {
  const [accounts, setAccounts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [open, setOpen] = useState(true);
  const [appGroups, setAppGroups] = useState([]);
  const [apps, setApps] = useState([]);

  const { data }: any = useGetList(
    'accounts',
    {
      sort: { field: 'login', order: 'DESC' },
    },
  );

  const groupsList: any = useGetList(
    'groupsFilter',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  const appGroupsList: any = useGetList(
    'appGroups',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  const { data: appsData }: any = useGetList(
    'appsList',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (appGroupsList.data) {
      setAppGroups(appGroupsList.data?.filter((gorup: any) => gorup.isEnabled)
        .map((record: any) => ({ id: record._id, name: record.name })));
    }
  }, [appGroupsList.data]);


  useEffect(() => {
    if (data) {
      setAccounts(data?.map((record: any) => ({ id: record._id, name: record.login })));
    }
  }, [data]);

  useEffect(() => {
    if (groupsList.data) {
      setGroups(groupsList.data?.map((record: any) => ({ id: record._id, name: record.name })));
      setGroupsData(groupsList.data);
      setOpen(false);
    }
  }, [groupsList.data]);

  useEffect(() => {
    if (appsData) {
      setApps(appsData?.map((record: any) => ({ id: record._id, name: record.name })));
    }
  }, [appsData]);

  return (
    <Edit
      title='Edit App'
      mutationMode='pessimistic'
      {...props}
    >
      <TabbedForm>
        <TabbedForm.Tab
          label='App data'
        >
          <Grid container sx={{ pl: '10px', pr: '30px' }}>
            <Grid item xs={12} sx={{ display: 'flex' }}>
              <Typography variant="h6">General</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <TextInput helperText={false} source='name' isRequired sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <TextInput helperText={false} source='package' isRequired sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={1.5} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  choices={[
                    { id: 'Android', name: 'Android' },
                    { id: 'iOS', name: 'iOS' }
                  ]}
                  source='os'
                  label='OS'
                  isRequired
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex' }}>
                <NumberInput helperText={false} source='keitaro_id' label='Keitaro ID' sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex' }}>
                <NumberInput helperText={false} source='optoAppId' label='Opto app ID' sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex' }}>
                <BooleanInput
                  helperText={false}
                  sx={{ width: '100%', paddingTop: '13px' }}
                  source='isBaned'
                  label='Banned' />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={4.15} sx={{ display: 'flex' }} >
                <TextInput helperText={false} source='url' label='Google Play App Url' isRequired sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={2.66} sx={{ display: 'flex' }} >
                <TextInput helperText={false} source='tg_img' label='Telegram Image Url' isRequired sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={2.3} sx={{ display: 'flex' }} >
                <AutocompleteInput
                  helperText={false}
                  source='fallbackApp'
                  choices={apps}
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <TextInput helperText={false} source='category' isRequired sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  source='vertical'
                  choices={[
                    { id: 'Gambling', name: 'Gambling' },
                    { id: 'Betting', name: 'Betting' },
                    { id: 'Dating', name: 'Dating' },
                    { id: 'Finance', name: 'Finance' },
                  ]}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={1.62} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  source='promotionType'
                  choices={[
                    { id: 'Naming', name: 'Naming' },
                    { id: 'Deep Link', name: 'Deep Link' },
                    { id: 'Naming + Deep Link', name: 'Naming + Deep Link' },
                  ]}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={2.7} sx={{ display: 'flex' }} >
                <SelectArrayInput
                  helperText={false}
                  source='source'
                  choices={[
                    { id: 'Facebook', name: 'Facebook' },
                    { id: 'UAC', name: 'UAC' },
                    { id: 'Unity', name: 'Unity' },
                    { id: 'TikTok', name: 'TikTok' },
                    { id: 'OneLink', name: 'OneLink' },
                  ]}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex' }} >
                <NumberInput helperText={false} source='ageRating' sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={0.7} sx={{ display: 'flex', paddingTop: '14px' }} >
                <BooleanInput helperText={false} label="Aviator" source="isAviator" />
              </Grid>
              <Grid item xs={0.7} sx={{ display: 'flex', paddingTop: '14px' }} >
                <BooleanInput helperText={false} label="Plinko" source="isPlinko" />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={7} sx={{ display: 'flex' }} >
                <TextInput
                  source='oneLink'
                  fullWidth
                  helperText={false}
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <TextInput
                label='Click attribution link'
                source='click_attr'
                fullWidth
                helperText={false}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <TextInput
                label='Impression attribution Link'
                source='impression_attr'
                fullWidth
                helperText={false}
                sx={{ width: '100%' }}
              />
            </Grid>


            <Grid item xs={12} sx={{ display: 'flex', marginTop: '15px' }}>
              <Typography variant="h6">Push service data</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={2.6} sx={{ display: 'flex' }} >
                <TextInput
                  source='onesignal_id'
                  label='Onesignal ID'
                  sx={{ width: '100%' }}
                  helperText={false}
                />
              </Grid>
              <Grid item xs={3.8} sx={{ display: 'flex' }} >
                <TextInput
                  source='os_api_key'
                  label='OS API Key'
                  sx={{ width: '100%' }}
                  helperText={false}
                />
              </Grid>

              <Grid item xs={2} sx={{ display: 'flex' }} >
                <AutocompleteInput
                  helperText={false}
                  source='appGroup'
                  label='Group'
                  choices={appGroups}
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={2.6} sx={{ display: 'flex' }} >
                <TextInput
                  helperText={false}
                  source='fcmKey'
                  label='FCM Key'
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>


            <Grid item xs={12} sx={{ display: 'flex', marginTop: '15px' }}>
              <Typography variant="h6">Facebook</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={1.1} sx={{ display: 'flex', paddingTop: '14px' }} >
                <BooleanInput helperText={false} source='fbMark' label='FB Mark' sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={1.9} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  source='fb_account'
                  label='FB Account'
                  choices={accounts}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <TextInput
                  helperText={false}
                  source='fb_id'
                  label='FB ID'
                  isRequired
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={7} sx={{ display: 'flex' }} >
                <TextInput
                  helperText={false}
                  source='post_form'
                  fullWidth
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={1.1} sx={{ display: 'flex', paddingTop: '14px' }} >
                <BooleanInput helperText={false} label='FB Button' source='fbBtnAvailable' sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={1.9} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  source='fb_share_type'
                  choices={[
                    { id: 'changePackage', name: 'Change Package' },
                  ]}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  source='fb_share_time'
                  choices={[
                    { id: '5', name: '5' },
                    { id: '10', name: '10' },
                    { id: '20', name: '20' },
                    { id: '30', name: '30' },
                    { id: '60', name: '60' },
                  ]}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={7} sx={{ display: 'flex' }} >
                <TextInput
                  helperText={false}
                  source='post_form_app'
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', marginTop: '15px' }}>
              <Typography variant="h6">GEO</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={3} sx={{ display: 'flex' }} >
                <AutocompleteArrayInput
                  source='closedGeos'
                  choices={closedGeos}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={3} sx={{ display: 'flex' }} >
                <AutocompleteArrayInput
                  source='openGeos'
                  choices={closedGeos}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={4} sx={{ display: 'flex' }} >
                <SelectInput
                  label='Show GEO'
                  source='showClosed'
                  choices={[
                    { id: true, name: 'Closed' },
                    { id: false, name: 'Open' },
                  ]}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px', marginTop: '30px' }}>
              <Grid item xs={1.5} sx={{ display: 'flex', paddingTop: '13px' }} >
                <BooleanInput
                  helperText={false}
                  source='bot_available'
                  label='Available in bot'
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <DateTimeInput
                  helperText={false}
                  source='issueDate'
                  label='Connection Date'
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex' }} >
                <NumberInput
                  helperText={false}
                  source='weight'
                  label='App weight'
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Grid>

          <div>
            {/*<Box display={{ xs: 'block', sm: 'flex' }}>*/}
            {/*  <Box flex={1}>*/}
            {/*    <TextInput source='name' isRequired sx={{ width: 300 }} />*/}
            {/*  </Box>*/}
            {/*  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <TextInput source='package' isRequired sx={{ width: 300 }} />*/}
            {/*  </Box>*/}
            {/*  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <SelectInput*/}
            {/*      choices={[*/}
            {/*        { id: 'Android', name: 'Android' },*/}
            {/*        { id: 'iOS', name: 'iOS' }*/}
            {/*      ]}*/}
            {/*      source='os'*/}
            {/*      label='OS'*/}
            {/*      isRequired*/}
            {/*      sx={{ width: 300 }}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*  <Box flex={3} ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <NumberInput source='keitaro_id' label='Keitaro ID' fullWidth />*/}
            {/*  </Box>*/}
            {/*  <Box flex={2} ml={{ xs: 0, sm: '0.5em' }} sx={{mt: '13px' }}>*/}
            {/*    <BooleanInput sx={{ ml: '20px' }} source='isBaned' label='Banned' />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<Box display={{ xs: 'block', sm: 'flex' }}>*/}
            {/*  <Box flex={1}>*/}
            {/*    <TextInput source='onesignal_id' label='Onesignal ID' sx={{ width: 300 }} />*/}
            {/*  </Box>*/}
            {/*  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <TextInput source='os_api_key' label='OS API Key' sx={{ width: 300 }} />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<TextInput source='url' isRequired fullWidth />*/}
            {/*<TextInput source='tg_img' label='Telegram Image Url' isRequired fullWidth />*/}
            {/*<Box display={{ xs: 'block', sm: 'flex' }}>*/}
            {/*  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <TextInput source='category' isRequired sx={{ width: 300 }} />*/}
            {/*  </Box>*/}
            {/*  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <SelectInput*/}
            {/*      source='vertical'*/}
            {/*      choices={[*/}
            {/*        { id: 'Gambling', name: 'Gambling' },*/}
            {/*        { id: 'Betting', name: 'Betting' },*/}
            {/*        { id: 'Dating', name: 'Dating' },*/}
            {/*        { id: 'Finance', name: 'Finance' },*/}
            {/*      ]}*/}
            {/*      sx={{ minWidth: '300px' }}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <AutocompleteInput*/}
            {/*      source='appGroup'*/}
            {/*      choices={appGroups}*/}
            {/*      sx={{ minWidth: '300px' }}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>*/}
            {/*  <Box ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <SelectInput sx={{ width: 300 }} source='fb_account' choices={accounts} />*/}
            {/*  </Box>*/}
            {/*  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <TextInput source='fb_id' label='Fb Id' isRequired sx={{ width: 300 }} />*/}
            {/*  </Box>*/}
            {/*  <Box ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <SelectInput sx={{ width: 300 }} source='fb_share_type' choices={[*/}
            {/*      { id: 'changePackage', name: 'Change Package' },*/}
            {/*    ]} />*/}
            {/*  </Box>*/}
            {/*  <Box ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <SelectInput sx={{ width: 300 }} source='fb_share_time' choices={[*/}
            {/*      { id: '5', name: '5' },*/}
            {/*      { id: '10', name: '10' },*/}
            {/*      { id: '20', name: '20' },*/}
            {/*      { id: '30', name: '30' },*/}
            {/*      { id: '60', name: '60' },*/}
            {/*    ]} />*/}
            {/*  </Box>*/}
            {/*  <Box sx={{ marginLeft: '20px' }}>*/}
            {/*    <BooleanInput label='FB Button' source='fbBtnAvailable' />*/}
            {/*  </Box>*/}
            {/*  <Box sx={{ marginLeft: '20px' }}>*/}
            {/*    <BooleanInput source='fbMark' />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>*/}
            {/*  <Box flex={3} ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <NumberInput source='ageRating' fullWidth />*/}
            {/*  </Box>*/}
            {/*  <Box flex={1} ml={{ xs: 0, ml: '10px', sm: '0.5em' }}>*/}
            {/*    <SelectInput source='promotionType' choices={[*/}
            {/*      { id: 'Naming', name: 'Naming' },*/}
            {/*      { id: 'Deep Link', name: 'Deep Link' },*/}
            {/*      { id: 'Naming + Deep Link', name: 'Naming + Deep Link' },*/}
            {/*    ]} sx={{ width: 300 }} />*/}
            {/*  </Box>*/}
            {/*  <Box ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <SelectArrayInput sx={{ width: 300 }} source='source' choices={[*/}
            {/*      { id: 'Facebook', name: 'Facebook' },*/}
            {/*      { id: 'UAC', name: 'UAC' },*/}
            {/*      { id: 'Unity', name: 'Unity' },*/}
            {/*      { id: 'TikTok', name: 'TikTok' },*/}
            {/*      { id: 'OneLink', name: 'OneLink' },*/}
            {/*    ]} />*/}
            {/*  </Box>*/}
            {/*  <Box sx={{ marginLeft: '30px' }}>*/}
            {/*    <BooleanInput label='Aviator' source='isAviator' />*/}
            {/*  </Box>*/}
            {/*  <Box>*/}
            {/*    <BooleanInput label='Plinko' source='isPlinko' />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>*/}
            {/*  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <AutocompleteArrayInput source='closedGeos' choices={closedGeos} sx={{ minWidth: '300px', maxWidth: '500px' }} />*/}
            {/*  </Box>*/}
            {/*  <Box sx={{ ml: '20px' }}>*/}
            {/*  </Box>*/}
            {/*  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <AutocompleteArrayInput source='openGeos' choices={closedGeos} sx={{ minWidth: '300px', maxWidth: '500px' }} />*/}
            {/*  </Box>*/}
            {/*  <Box sx={{ ml: '20px' }}>*/}
            {/*    <SelectInput*/}
            {/*      label='Show GEO'*/}
            {/*      source='showClosed'*/}
            {/*      choices={[*/}
            {/*        { id: true, name: 'Closed' },*/}
            {/*        { id: false, name: 'Open' },*/}
            {/*      ]} />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<TextInput*/}
            {/*  source='post_form'*/}
            {/*  fullWidth*/}
            {/*  helperText={false}*/}
            {/*/>*/}
            {/*<TextInput*/}
            {/*  source='post_form_app'*/}
            {/*  fullWidth*/}
            {/*  helperText={false}*/}
            {/*/>*/}
            {/*<TextInput*/}
            {/*  source='oneLink'*/}
            {/*  fullWidth*/}
            {/*  helperText={false}*/}
            {/*/>*/}
            {/*<TextInput*/}
            {/*  label='Click attribution link'*/}
            {/*  source='click_attr'*/}
            {/*  fullWidth*/}
            {/*  helperText={false}*/}
            {/*/>*/}
            {/*<TextInput*/}
            {/*  label='Impression attribution Link'*/}
            {/*  source='impression_attr'*/}
            {/*  fullWidth*/}
            {/*  helperText={false}*/}
            {/*/>*/}
            {/*<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>*/}
            {/*  <Box ml={{ xs: 0, sm: '0.5em' }} sx={{ mt: '15px' }} >*/}
            {/*    <BooleanInput source='bot_available' label='Available in bot' />*/}
            {/*  </Box>*/}
            {/*  <Box ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <DateTimeInput  source='issueDate' label='Connection Date' />*/}
            {/*  </Box>*/}
            {/*  <Box ml={{ xs: 0, sm: '0.5em' }}>*/}
            {/*    <NumberInput source='weight' label='App weight' />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
          </div>


        </TabbedForm.Tab>

        <TabbedForm.Tab label='Google ADS Data'>
          <TextInput
            source='google_ads_identifier'
            label='Identifier'
            fullWidth
            helperText={false}
          />
          <ArrayInput {...props} source='google_ads_rk' label='RK IDs'>
            <SimpleFormIterator
              disableReordering
              getItemLabel={index => `#${index + 1}`}
              sx={{
                '& .RaSimpleFormIterator-list': {
                  display: 'flex',
                  flexWrap: 'wrap',
                },
                '& .RaSimpleFormIterator-line': {
                  borderBottom: '0',
                },
                '& .RaSimpleFormIterator-form': {
                  flexDirection: 'row'
                }
              }}
            >
              <TextInput
                resettable
                source='rk_id'
                label='ID'
                sx={{
                  marginRight: '20px'
                }}
              />
              <AutocompleteInput
                label='Team'
                source='rk_team'
                choices={groups}
                optionText="name"
                optionValue="name"
                sx={{
                  width: '190px'
                }}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </TabbedForm.Tab>

        {!open && (
          <TabbedForm.Tab
            label='Access Management'
          >
            <FunctionField
              render={(record: any) => {
                if (record.os === 'iOS') {
                  return (
                    <SelectInput
                      helperText={false}
                      choices={[
                        { id: true, name: 'All groups' },
                        { id: false, name: 'Access groups' }
                      ]}
                      source='showForAll'
                      label='Visibility'
                      isRequired
                      sx={{ width: '100%', marginBottom: '30px' }}
                    />
                  );
                }
              }}
            />
            <ArrayInput {...props} source='groups' label='Groups'>
              <SimpleFormIterator
                disableReordering
                getItemLabel={index => `#${index + 1}`}
                sx={{
                  '& .RaSimpleFormIterator-line': {
                    borderBottom: '0',
                  },
                  '& .RaSimpleFormIterator-form': {
                    flexDirection: 'row'
                  }
                }}
              >
                <AutocompleteInput
                  sx={{ minWidth: '250px', mr: '10px' }}
                  source='group'
                  label='Select group'
                  choices={groups}
                />
                <FormDataConsumer>
                  {({ scopedFormData, getSource, ...rest }: any) => {
                    let users: any[];
                    let exceptUsers: any[];
                    // @ts-ignore
                    if (scopedFormData.group) {
                      // @ts-ignore
                      const { mates }: any = groupsData
                        .find((group: any) => (group.id === scopedFormData.group));

                      users = mates
                        .map((user: string) => ({ id: user, name: user }));

                      exceptUsers = users;
                    } else {
                      users = [];
                      exceptUsers = [];
                    }
                    return (
                      <>
                        <AutocompleteArrayInput
                          sx={{ minWidth: '300px', mr: '10px' }}
                          label='Select users'
                          source={getSource('users')}
                          choices={[ { id: 'all', name: 'All Users' } , ...users]}
                          {...rest}
                        />
                        <AutocompleteArrayInput
                          sx={{ minWidth: '300px' }}
                          label='Excluded Users'
                          source={getSource('excluded_users')}
                          choices={exceptUsers}
                          {...rest}
                        />
                      </>

                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </TabbedForm.Tab>
        )}

      </TabbedForm>
    </Edit>
  );
};

export default AppEdit;
